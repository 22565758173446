import * as React from 'react'
import PropTypes from "prop-types"

const PageTile = ({ children, outerClass, innerClass, background }) => {


    return (
        <>
            <section className={`page-tile ${outerClass} ${background ? background : ""}`}>
                <div className={`inner-content-wrapper ${innerClass}`}>
                    {children}
                </div>
            </section>
        </>
    )
}

PageTile.propTypes = {
    outerClass: PropTypes.string,
    innerClass: PropTypes.string,
}

PageTile.defaultProps = {
    outerClass: ``,
    innerClass: ``,
}

export default PageTile;