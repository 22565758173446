import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import PageTile from "../layout/pageTile"
import HeaderGraphic from "../images/randomRoseCircles.svg"
import Particles from "../images/Particles.svg"
import MainMenu from "./main-menu"

const Header = ({ data, teaser  }) => (
  <>
  <header>
  <div className="site-header">
    <img className="headerGraph" src={HeaderGraphic} alt="Rose Header Graphic" />
    <div className="header-container inner-content-wrapper">
    <MainMenu></MainMenu>

    
    <h4>{data.subtitle}</h4>
    <h3>{data.title}</h3>
          <p dangerouslySetInnerHTML={{ __html: data.smallTeaser }}></p>
    <img className="particlesGraph" src={Particles} alt="Graphical Particles" />
    </div>
    
  </div>
  <PageTile innerClass="main-grid-columns header-teaser">
        {teaser !== false && <> <p className="teaserFull" dangerouslySetInnerHTML={{ __html: data.teaserFull }}></p>
          <p className="teaserSmall" dangerouslySetInnerHTML={{ __html: data.teaserSmall }}></p></>}
    </PageTile>
    </header>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
