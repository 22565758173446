/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import languageData from "../languageFiles/DE.json"
import Header from "../components/header"
import "../styles/stylesloader.scss"
import Footer from "../components/footer"

const Layout = ({ children, teaser }) => {
  

  return (
    <>
      <Header data={languageData.header} teaser={teaser} />

      <main className="main-content-wrapper">
        {children}
      </main>

      <Footer data={languageData.footer} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
